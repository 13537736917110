var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"col-12 mb-4"},[_c('div',{staticClass:"dashboard_card dashboard_card_left"},[_c('div',{staticClass:"dashboard_card_header"},[_c('h5',{staticClass:"dashboard_card_header__title text--bold text--capital"},[_vm._v(" task ")]),_c('div',[(_vm.userCanCreateTask)?_c('button',{staticClass:"mx-2 button button-new text--capital",attrs:{"type":"button"},on:{"click":_vm.openTaskModal}},[_c('ion-icon',{staticClass:"text--600",attrs:{"name":"add-outline"}}),_c('span',{staticClass:"d-inline-flex ml-2 text--600"},[_vm._v("new task")])],1):_vm._e(),(_vm.userCanViewTask)?_c('router-link',{staticClass:"mx-2 button button-add text--capital",attrs:{"to":"/all-tasks"}},[_c('img',{attrs:{"src":require("@/assets/img/category.svg"),"alt":"","srcset":""}}),_c('span',{staticClass:"d-inline-flex ml-2"},[_vm._v("all task")])]):_vm._e()],1)]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-late"},[_vm._m(0),_c('div',[_c('span',{staticClass:"task-text--big text--600"},[_vm._v(" "+_vm._s(_vm.stats.overDue ? _vm.stats.overDue : 0)+" ")])]),_vm._m(1)])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-due"},[_vm._m(2),_c('div',[_c('span',{staticClass:"task-text--big text--600"},[_vm._v(" "+_vm._s(_vm.stats.due ? _vm.stats.due : 0)+" ")])]),_vm._m(3)])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-upcoming"},[_vm._m(4),_c('div',[_c('span',{staticClass:"task-text--big text--600"},[_vm._v(" "+_vm._s(_vm.stats.upcoming ? _vm.stats.upcoming : 0)+" ")])]),_vm._m(5)])])])])]),_c('CreateTask',{attrs:{"show":_vm.newTaskModal},on:{"close":_vm.openTaskModal,"error-creating-task":_vm.errorCreatingTask,"task-created-successfully":_vm.taskCreatedSuccessfully}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"task-icon"},[_c('img',{attrs:{"src":require("@/assets/img/redcdr.svg"),"alt":"","srcset":""}})]),_c('span',{staticClass:"task-subtitle text--capital"},[_vm._v(" overdue ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-indicator"},[_c('div',{staticClass:"task-indicator-child task-indicator--danger"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"task-icon"},[_c('img',{attrs:{"src":require("@/assets/img/orgcdr.svg"),"alt":"","srcset":""}})]),_c('span',{staticClass:"task-subtitle text--capital"},[_vm._v(" Due Today ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-indicator"},[_c('div',{staticClass:"task-indicator-child task-indicator--primary"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-1"},[_c('span',{staticClass:"task-icon"},[_c('img',{attrs:{"src":require("@/assets/img/grncdr.svg"),"alt":"","srcset":""}})]),_c('span',{staticClass:"task-subtitle text--capital"},[_vm._v(" upcoming ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"task-indicator"},[_c('div',{staticClass:"task-indicator-child task-indicator--success"})])
}]

export { render, staticRenderFns }